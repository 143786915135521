@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor: #032B43;
$secondaryColor: #23395B;
$firstTertiaryColor: rgb(226 232 240);
$secondTertiaryColor: #104F55;

$fontFamily: monospace;

body {
  @apply bg-slate-200 font-mono;
}

.panel {
  border: 1px solid silver;
  border-radius: 4px;
}

.panel .panel-header {
  background-color: whitesmoke;
  background-image: linear-gradient(rgb(0, 0, 0, 0.08), whitesmoke);
  border-bottom: 1px solid silver;
  border-radius: 4px 4px 0px 0px;
  padding: 10px;
}

.panel .panel-body {
  padding: 10px;
  background-color: whitesmoke;
  margin: 0px;
  border-radius:0px 0px 4px 4px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgb(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
}

.modal-container .modal {
  color: black;
  // @apply w-6/12;
  margin: auto;
  @apply mt-[10%];
  background-color: whitesmoke;
  border: 1px solid silver;
  border-radius: 4px;
  padding-bottom: 1px;
}

.modal .modal-header,
.confirm-modal .confirm-modal-header {
  background-color: whitesmoke;
  background-image: linear-gradient(rgb(0, 0, 0, 0.1), whitesmoke);
  border-bottom: 1px solid silver;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px;
}

.modal .modal-body {
  padding: 10px;
  background-color: whitesmoke;
  overflow: auto;
  min-height: 200px;
  max-height: 400px;
}

.modal .modal-close-button,
.confirm-modal .modal-close-button {
  border: 1px solid silver;
  // box-shadow: 10x 10px $firstTertiaryColor;
  border-radius: 4px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  //color: $secondaryColor;
  @apply text-pink-600
}
.confirm-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
}

.confirm-modal {
  color: black;
  @apply w-4/12;
  margin: auto;
  @apply mt-[10%];
  background-color: whitesmoke;
  border: 1px solid silver;
  border-radius: 4px;
  padding-bottom: 1px;
}

.confirm-modal-body{
  display: flex;
  justify-content: center;
  padding: 10px;
}

.confirm-modal-body .buttons{
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.pagination {
  margin-top: 10px;
}

.pagination .pagination-item {
  border: 1px solid silver;
  border-radius: 4px;
  padding: 3px 5px 3px 5px;
  background-color: white;
  color: rgb(0, 0, 0, 0.8);
  font-family: $fontFamily;
  // font-weight: bold;
  margin: 3px;
  // box-shadow: 0px 0px 2px gray;
  cursor: pointer;
}

.pagination .active{
  border:1px solid $secondaryColor;
  background-color: $firstTertiaryColor;
  color: $secondaryColor;
}

.table {
  border: 1px solid silver;
  border-radius: 5px;
  color: rgb(0, 0, 0, 0.8);
  width: 100%;
}

.table tr th {
  border: 1px solid silver;
  background-color: whitesmoke;
  //background-image: linear-gradient(rgba(0, 0, 0, 0.08), whitesmoke);
  padding: 5px;
}

.table tr td {
  border: 1px solid silver;
  padding: 5px;
}

.table .row-selected:hover {
  background-color: rgba(0, 0, 0, 0.08);
  @apply text-pink-600;
  // color: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.table .with-row-child {
  background-color: rgb(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.08);
  font-weight: bold;
}



.btn-primary {
  border: 1px solid $secondaryColor;
  // box-shadow: 10x 10px $firstTertiaryColor;
  border-radius: 4px;
  background-color: $secondaryColor;
  padding: 5px;
  color: white;
  box-shadow: 0px 0px 4px gray;
}

.btn-default {
  border: 1px solid silver;
  // box-shadow: 10x 10px $firstTertiaryColor;
  border-radius: 4px;
  background-color: white;
  padding: 5px;
  color: rgb(0, 0, 0, 0.8);
  // box-shadow: 0px 0px 4px gray;
}

.btn-danger {
  @apply text-slate-900 bg-red-300 px-2 py-1 border  border-red-400 rounded-sm;
}

.form-input label {
  color: $secondaryColor;
}

.text-field {
  border: 1px solid silver;
  border-radius: 4px;
  padding: 5px;
  outline: none;
}

.text-field-required {
  border: 1px solid rgb(222, 141, 141);
  border-radius: 4px;
  padding: 5px;
  outline: none;
}

.text-field:focus {
  border: 1px solid $secondaryColor;
  outline: none;
}

.alert-notification{
  background-color: rgb(183, 239, 221);
  padding:10px;
  border:1px solid rgb(78, 159, 132);
  border-radius: 4px;
  color: black;
  text-align: center;
  width: 50%;
  position: fixed;
  top:10%;
  left: 25%;
}

.alert-fail{
  background-color: rgb(244, 189, 189);
  padding:10px;
  border:1px solid rgb(211, 134, 154);
  border-radius: 4px;
  color: black;
}